import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  validation_messages: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiServiceService,
    private storageService: StorageServiceService,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) { }

  ngOnInit() {
    let email = new RegExp(
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
    );
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.pattern(email), Validators.required]),
      ],
      password: ['', Validators.required],
    });
    this.validation_messages = {
      email: [
        { type: 'required', message: 'Email is required.' },
        { type: 'pattern', message: "Doesn't look like a valid email" },
      ],
      password: [{ type: 'required', message: 'Password is required.' }],
    };
  }

  login() {
    // 'admin@gmail.com','admin123'
    if (this.loginForm.valid) {
      this.loader.start();
      let data = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
      };
      this.apiService
        .postMethod('/user/login/', data)
        .subscribe((response: any) => {
          if (response.code == 200) {
            console.log(response);
            this.loader.stop();
            this.storageService.putDataInStorage("KUSA", JSON.stringify(response.data))
            this.router.navigate(['/dashboard']);
            this.toastr.success(response.message);
          } else {
            this.loader.stop();
            this.toastr.error(response.err);
          }
        });
    }
  }
}
