<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <!-- <h2 class="card-title">Personal Details</h2> -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Resource Title *</label>
                            <input type="text" class="form-control" placeholder="Title" [(ngModel)]="title" />
                        </div>
                    </div>
                </div>
                <div class="row"></div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Resource Discription *</label>
                            <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Resource Image *</label>
                        <span class="cross">x</span>
                        <img *ngIf="showImg" [src]="imgData" class="imgClass">
                        <label for="fileUpload">
                            <img class="camera-icon" src="../../../../assets/photo_camera.svg">
                            <input hidden type='file' id="fileUpload" (change)="imgUpload($event)" accept="image/*">
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Subject*</label>
                            <div class="form-group">
                                <ng-select [items]="subjects" (change)="changeLeagueOwner()" bindLabel="subject_name" [multiple]="true"
                                    placeholder="Select Subjects" [(ngModel)]="selectSubjects">
                                </ng-select>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Grade*</label>
                            <ng-select [items]="grades" bindLabel="grade_level" (change)="changeLeagueOwner1()" placeholder="Select Grade" [multiple]="true"
                                [(ngModel)]="selectGrade">
                            </ng-select>


                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="update ml-auto mr-auto">
                        <button type="submit" class="btn btn-primary btn-round" (click)="onSubmit()">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row" *ngIf='blogList.length>0'>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Free Resource List</h2>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Sn No</th>
                            <th scope="col">Title</th>
                            <th scope="col">Image</th>
                            <th scope="col">Delete</th>

                        </tr>
                    </thead>
                    <tbody *ngFor="let item of blogList;let i =index">
                        <tr>
                            <td scope="row">{{i +1}}</td>
                            <td>{{item.title}}</td>
                            <td><img title="Delete" class="show-img" [src]="item.url"></td>
                            <td> <i class="fa fa-trash" aria-hidden="true" style="font-size: 20px;cursor: pointer;"
                                    (click)="removeFree(item)" title="Delete"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>