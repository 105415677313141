import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';
import * as moment from 'moment';
import { DownloadFileServiceService } from 'src/app/services/download-file-service.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  displayedColumns: string[] = ['position', 'stu_name', 'stu_phone', 'tut_name', 'tut_phone', 'subject', 'grade', 'time', 'date', 'plateform', 'status'];
  dataSource: any;
  showTable: boolean = false;
  fromDate: any;
  toDate: any;
  showToDate: boolean = false;
  type: any = 'All';
  sessionData: any = []

  ELEMENT_DATA = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router, private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService, private storageService: StorageServiceService,
    private download: DownloadFileServiceService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }
  onSubmit() {
    debugger
    if (!this.fromDate || !this.toDate || !this.type) {
      this.toastr.error("All * field is required");
      return
    }
    this.loader.start()
    let user: any = JSON.parse(this.storageService.getDataFromStorage('KUSA')!)
    let data: any = {
      user_id: user.user_id,
      from_date: this.fromDate,
      to_date: this.toDate,
      type: this.type
    }
    this.apiService
      .postMethod('/admin/report/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          // console.log(response)
          this.sessionData = response.sessionData
          this.showTable = true;
          console.log(response.sessionData)
          this.ELEMENT_DATA = response.sessionData
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // console.log(this.dataSource);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  downloadFile(format: any) {
    debugger
    if (format == 'pdf') {

    } else if (format == 'excel') {
      if (!this.sessionData.length) {
        return this.toastr.error("No any data for download");
      }
      this.downloadExcelFile(this.sessionData)
    }

  }
  downloadExcelFile(resData) {
    // debugger
    var exportdata: any = [];
    resData.forEach((e: any) => {
      let data: any = {
        StudentFirstName: e.student_id ? e.student_id.first_name : "",
        StudentlastName: e.student_id ? e.student_id.last_name : "",
        StudentEmail: e.student_id.email ? e.student_id.email.email_id : "",
        StudentPhone: e.student_id.phone ? e.student_id.phone.phone_no : "",
        TutorFirstName: e.tutor_id ? e.tutor_id.first_name : "",
        TutorLastName: e.tutor_id ? e.tutor_id.last_name : "",
        TutorEmail: e.tutor_id.email ? e.tutor_id.email.email_id : "",
        TutorPhone: e.tutor_id ? e.tutor_id.phone.phone_no : "",
        Date: e.select_date ? e.select_date : "",
        SlotTime: e.slot_time ? e.slot_time : "",
        Time: e.select_time ? e.select_time : '',
        Plateform: e.plateform ? e.plateform : "",
        SubjectName: e.subject_id ? e.subject_id.subject_name : "",
        Grade: e.grade_id ? e.grade_id.grade_level : "",
        CreatedAt: e.updatedAt ? e.updatedAt : "",
      }
      if (e.schedule == "1") {
        data['Status'] = "Pending"
      } else if (e.schedule == '2') {
        data['Status'] = "Cancel"

      } else if (e.schedule == '3') {
        data['Status'] = "Reschedule"

      } else if (e.schedule == '4') {
        data['Status'] = "Scheduled"

      } else if (e.schedule == '5') {
        data['Status'] = "Completed"

      }
      exportdata.push(data);

    });

    this.download.DownloadInExcel("Report Data", exportdata);
  }
  datePicker() {
    if (this.fromDate) {
      this.showToDate = true
      var todate = moment(new Date(this.fromDate));
    }
  }
}
