<div class="row">
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-single-02 text-warning"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Student Visited</p>
              <p class="card-title">{{totalCount?.student_count}}
              <p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-badge text-success"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Parent Visited</p>
              <p class="card-title">{{totalCount?.parent_count}}
              <p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-single-copy-04 text-danger"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Total Tutors On Boarded</p>
              <p class="card-title">{{totalCount?.tutor_count}}
              <p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body ">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center icon-warning">
              <i class="nc-icon nc-bullet-list-67 text-primary"></i>
            </div>
          </div>
          <div class="col-7 col-md-8">
            <div class="numbers">
              <p class="card-category">Institute Requested</p>
              <p class="card-title">{{totalCount?.institute_count}}
              <p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-4">
    <div class="card ">
      <div class="card-header ">
        <h5 class="card-title">Top Rated Tutors</h5>

      </div>
      <div class="card-body-1 ">
        <div class="item" *ngFor="let item of top_rated">
          <div class="item_img" *ngIf='item.profile_pic'>
            <img [src]="item.profile_pic" alt="">
          </div>
          <div class="item_img" *ngIf='!item.profile_pic'>
            <img src="../../../assets/profile-picture.png" alt="">
          </div>
          <div style="margin-left: 20px;">
            {{item._id? item._id.first_name:''}}
          </div>
          <div style="margin-left: 20px;">
            <ngb-rating style="color: red;font-size: 20px;" [(rate)]="item.avgQuantity" [starTemplate]="t"
              [readonly]="true" [max]="5"></ngb-rating>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="row">
      <div class="col md-6">
        <div class="card">
          <div class="card-header">
            <h2 class="card-title">Total Sessions</h2>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <p class="card-title">{{totalCount?.total_session ? totalCount?.total_session :0 }}
                  <p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col md-6">
        <div class="card">
          <div class="card-header">
            <h2 class="card-title">Pending Sessions</h2>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <p class="card-title">{{totalCount?.pending_session ? totalCount?.pending_session:0}}
                  <p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="row">
      <div class="col md-6">
        <div class="card">
          <div class="card-header">
            <h2 class="card-title">Complete Sessions</h2>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <p class="card-title">{{totalCount?.complete_session ? totalCount?.complete_session :0 }}
                  <p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-md-4">
      <div class="card ">
        <div class="card-header ">
          <h5 class="card-title">Most Liked Subject</h5>
        </div>
        <div class="card-body-1 liked" >
         <div class="sn_no" *ngFor="let item of sub; let i = index">
          <div class="first">
            {{i+1}}
          </div>
          <div class="subject">
            {{item.subject}}
          </div>
         </div>
        </div>
      </div>
    </div> -->
</div>