import { ViewChild, AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})
export class ParentComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'email', 'phone', 'action'];
  dataSource: any;
  ELEMENT_DATA: any = []
  role: any

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router, private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService, private storageService: StorageServiceService) { }


  ngOnInit(): void {
    this.parentData()
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  parentData() {
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
    let data = {
      user_id: localStorage.user_id,
      role: "PARENT"
    }
    this.apiService
      .postMethod('/admin/get_user_list/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          console.log(response.data);
          this.loader.stop()
          this.ELEMENT_DATA = response.data
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          //  console.log(this.dataSource);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  viewdetails(data) {
    this.router.navigate(['/view-parent/' + data]);
  }
}
