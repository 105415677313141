import { Component, OnInit } from '@angular/core';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },
  { path: '/student', title: 'Student', icon: 'nc-single-02', class: '' },
  { path: '/parent', title: 'Parent', icon: 'nc-badge', class: '' },
  {
    path: '/tutor',
    title: 'Tutor',
    icon: 'nc-single-copy-04',
    class: '',
  },
  { path: '/institute', title: 'Institute', icon: 'nc-bullet-list-67', class: '' },
  { path: '/subject',         title: 'Subject/Course',        icon:'nc-tile-56',    class: '' },
  { path: '/grade',    title: 'Grade',        icon:'nc-caps-small', class: '' },
  { path: '/report',       title: 'Report',    icon:'nc-spaceship',  class: '' },
  { path: '/free-resource',       title: 'Free Resource',    icon:'nc-album-2',  class: '' },
  { path: '/package',       title: 'Package',    icon:'nc-box',  class: '' },
  { path: '/promo',       title: 'Promo Code',    icon:'nc-bag-16',  class: '' },
];

@Component({
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
}
