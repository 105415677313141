import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StudentComponent } from './pages/student/student.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ParentComponent } from './pages/parent/parent.component';
import { TutorComponent } from './pages/tutor/tutor.component';
import { ViewStudentComponent } from './pages/student/view-student/view-student.component';
import { ViewParentComponent } from './pages/parent/view-parent/view-parent.component';
import { ViewTutorComponent } from './pages/tutor/view-tutor/view-tutor.component';
import { InstituteComponent } from './pages/institute/institute.component';
import { SubjectCourseComponent } from './pages/subject-course/subject-course.component';
import { GradeComponent } from './pages/grade/grade.component';
import { ViewGradeComponent } from './pages/grade/view-grade/view-grade.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ReportsComponent } from './pages/reports/reports.component';
import { FreeResourceComponent } from './pages/free-resource/free-resource.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PackageComponent } from './pages/package/package.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PromoCodeComponent } from './pages/promo-code/promo-code.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    StudentComponent,
    ParentComponent,
    TutorComponent,
    ViewStudentComponent,
    ViewParentComponent,
    ViewTutorComponent,
    InstituteComponent,
    SubjectCourseComponent,
    GradeComponent,
    ViewGradeComponent,
    LoginComponent,
    ReportsComponent,
    FreeResourceComponent,
    PackageComponent,
    PromoCodeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SidebarModule,
    NavbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FooterModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center', maxOpened: 1,
      autoDismiss: true
    }),
    NgxUiLoaderModule,
    AngularEditorModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
