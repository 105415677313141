
	<div class="container h-100">
		<div class="d-flex justify-content-center h-100">
			<div class="user_card">
				<div class="d-flex justify-content-center">
					<div class="brand_logo_container">
						<img src="assets/download.jpg" class="brand_logo" alt="Logo">
					</div>
				</div>
				<div class="d-flex justify-content-center form_container">
					<form [formGroup]="loginForm" (ngSubmit)="login()"  #myLogin="ngForm">
						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="text" name="" class="form-control input_user"  formControlName="email" placeholder="email *">
							<!-- <div class="text-danger"
							*ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched || myClub.submitted)">
							Name is required.
						  </div> -->
						  <ng-container *ngFor="let validation of validation_messages.email">
							<div class="text-style"
							  *ngIf="loginForm.get('email').hasError(validation.type) && (loginForm.get('email').dirty || loginForm.get('email').touched || myLogin.submitted)">
							  {{ validation.message }}
							</div>
						  </ng-container>
						</div>
						<!-- </div> -->
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-key"></i></span>
							</div>
							<input type="password" name="" class="form-control input_pass"  formControlName="password" placeholder="password">
							<ng-container *ngFor="let validation of validation_messages.password">
								<div class="text-style"
								  *ngIf="loginForm.get('password').hasError(validation.type) && (loginForm.get('password').dirty || loginForm.get('password').touched || myLogin.submitted)">
								  {{ validation.message }}
								</div>
							  </ng-container>
						</div>
							<div class="d-flex justify-content-center mt-3 login_container">
								<button type="submit" name="button" class="btn login_btn">Login</button>
				   </div>
					</form>
				</div>
		
				<div class="mt-4">
					<div class="d-flex justify-content-center links">
						<a href="#">Forgot your password?</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	