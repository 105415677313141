import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StorageServiceService } from './services/storage-service.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin-panel';
  constructor(private storageService: StorageServiceService, private router: Router, private location: Location) {

  }
  ngOnInit() {
    // debugger
    let currentUrl = this.location.path();
    let userData = this.storageService.getDataFromStorage('KUSA');
    if (userData) {
      let login = currentUrl.split('/')[1];
      if (login == "login" || currentUrl == "") {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate([currentUrl]);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
}
