<div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <h2 class="card-title">Generate Report
                                <i class="fa fa-file-excel-o" style="cursor:pointer" title="Download Excel"
                                    (click)="downloadFile('excel')" aria-hidden="true"></i>
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Choose Option</label>
                                <select class="form-control" [(ngModel)]="type">
                                    <option value="All" selected>All</option>
                                    <option>PENDING</option>
                                    <option>CANCEL</option>
                                    <option>RESCHEDULE</option>
                                    <option>SCHEDULE</option>
                                    <option>COMPLETED</option>
                                </select>
                            </div>
                        </div>
                        <div class="col md-3">
                            <div class="form-group">
                                <label>Choose Date From *</label>
                                <br>
                                <input type="date" [(ngModel)]="fromDate" (change)="datePicker()">
                            </div>
                        </div>
                        <div class="col md-3" *ngIf="!showToDate">
                            <div class="form-group">
                                <label>Choose Date To *</label>
                                <br>
                                <input type="date" disabled [(ngModel)]="toDate">
                            </div>
                        </div>
                        <div class="col md-3" *ngIf="showToDate">
                            <div class="form-group">
                                <label>Choose Date To *</label>
                                <br>
                                <input type="date" [(ngModel)]="toDate" [min]="fromDate">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="text-design">
                                <button type="submit" class="btn btn-success btn-round" (click)="onSubmit()">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="mat-elevation-z8" *ngIf="showTable">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
                <td mat-cell *matCellDef="let i=index"> {{i+1}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="stu_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Student Name </th>
                <td mat-cell *matCellDef="let element"> {{element?.student_id.first_name}} </td>
            </ng-container>

            <!-- email Column -->
            <ng-container matColumnDef="stu_phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Student Phone </th>
                <td mat-cell *matCellDef="let element"> {{element?.student_id?.phone?.phone_no}} </td>
            </ng-container>

            <!-- institute Column -->
            <ng-container matColumnDef="tut_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tutor Name </th>
                <td mat-cell *matCellDef="let element"> {{element?.tutor_id?.first_name}} </td>
            </ng-container>


            <!-- phone Column -->
            <ng-container matColumnDef="tut_phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tutor Phone </th>
                <td mat-cell *matCellDef="let element"> {{element?.tutor_id?.phone?.phone_no}} </td>
            </ng-container>


            <!-- phone Column -->
            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject </th>
                <td mat-cell *matCellDef="let element"> {{element?.subject_id?.subject_name}} </td>
            </ng-container>


            <!-- phone Column -->
            <ng-container matColumnDef="grade">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Grade </th>
                <td mat-cell *matCellDef="let element"> {{element.grade_id?.grade_level}} </td>
            </ng-container>

            <!-- phone Column -->
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
                <td mat-cell *matCellDef="let element"> {{element?.slot_time}} </td>
            </ng-container>
            <!-- phone Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element"> {{element?.select_date | date:"dd-MM-yyyy"}} </td>
            </ng-container>
            <!-- phone Column -->
            <ng-container matColumnDef="plateform">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Plateform </th>
                <td mat-cell *matCellDef="let element"> {{element?.plateform}} </td>
            </ng-container>


            <!-- phone Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.schedule=='1'">Pending</span>
                    <span *ngIf="element.schedule=='2'">Cancel</span>
                    <span *ngIf="element.schedule=='3'">Reschedule</span>
                    <span *ngIf="element.schedule=='4'">Scheduled</span>
                    <span *ngIf="element.schedule=='5'">Completed</span>
                </td>
            </ng-container>

            <!-- action Column -->
            <!-- <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <td mat-cell *matCellDef="let element"> 
            <i class="fa fa-info-circle" title="View Details" aria-hidden="true" (click)="openModal()"></i>
          </td>  
          </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10,20]" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>