import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { DownloadFileServiceService } from 'src/app/services/download-file-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-view-tutor',
  templateUrl: './view-tutor.component.html',
  styleUrls: ['./view-tutor.component.scss']
})
export class ViewTutorComponent implements OnInit {
  tutor_id: any
  tutor_data: any
  amountShow: string
  amountPaid: string
  rating: string
  status: string
  selectGrade: any = [];
  grades: any = [];
  selectSubjects: any = [];
  subjects: any = [];
  edu: any = [];
  firstName: any = '';
  lastName: any = '';
  email: any = '';
  phone: any = '';
  gender: any = '';
  address: any = '';
  city: any = '';
  state: any = '';
  country: any = '';
  zipCode: any = '';
  subjectData: any = []
  gradeData: any = [];

  constructor(private router: Router, private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService, private storageService: StorageServiceService,
    private route: ActivatedRoute,
    private download: DownloadFileServiceService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tutor_id = params.id
    });
    if (this.tutor_id) {
      this.getData()
      this.getGrades()
      this.getSubjects()
    }
  }
  getData() {
    // debugger
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
    let data = {
      user_id: localStorage.user_id,
      id: this.tutor_id,
      role: 'tutor'

    }
    this.apiService
      .postMethod('/admin/get_user_data/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.tutor_data = response.data
          this.firstName = this.tutor_data?.first_name
          this.lastName = this.tutor_data?.last_name
          this.email = this.tutor_data?.email ? this.tutor_data?.email?.email_id : ""
          this.phone = this.tutor_data?.phone ? this.tutor_data.phone.phone_no : ' '
          this.address = this.tutor_data?.address ? this.tutor_data?.address : ' '
          this.city = this.tutor_data?.city ? this.tutor_data?.city : ' '
          this.state = this.tutor_data?.state ? this.tutor_data?.state : ' '
          this.country = this.tutor_data?.country ? this.tutor_data?.country : ' '
          this.zipCode = this.tutor_data?.zipCode ? this.tutor_data?.zipCode : ' '
          this.status = response.data.tutor_status
          this.edu = response.data.other_media.length ? response.data.other_media[1].edu : []
          this.amountShow = this.tutor_data.amount ? this.tutor_data.amount.show_amount : "0"
          this.amountPaid = this.tutor_data.amount ? this.tutor_data.amount.paid : "0"
          this.rating = this.tutor_data.tutor_rating ? this.tutor_data.tutor_rating : 1
          this.selectGrade = this.tutor_data?.grade_data
          this.selectSubjects = this.tutor_data?.subject_data
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  goBack() {
    this.router.navigate(['/tutor']);
  }
  update() {
    // debugger
    this.loader.start()
    let data = {
      user_id: this.tutor_id,
      first_name: this.firstName,
      last_name: this.lastName,
      phone: this.phone,
      country: this.country,
      address: this.address,
      state: this.state,
      city: this.city,
      zipCode: this.zipCode.toString()
    }
    if (this.subjectData.length > 0) {
      data['subject'] = this.subjectData
    }
    if (this.gradeData.length > 0) {
      data['grade_level'] = this.gradeData
    }
    this.apiService
      .postMethod('/user/edit_profile/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          // this.student_data = response.data
          console.log(response);
          this.toastr.success(response.message);

          this.router.navigate(['/tutor']);

        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }

  getGrades() {
    let data = {
      key: 1
    }
    this.apiService
      .postMethod('/user/get_grade_subjects/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.grades = response.data
          console.log(this.grades);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  getSubjects() {
    let data = {
      key: 0
    }
    this.apiService
      .postMethod('/user/get_grade_subjects/', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.subjects = response.data
          // console.log(this.grades);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  downloadFile(format: any) {
    // debugger
    if (format == 'pdf') {

    } else if (format == 'excel') {
      if (!this.tutor_data) {
        return this.toastr.error("No any data for download");
      }
      this.downloadExcelFile(this.tutor_data)
    }

  }
  downloadExcelFile(e) {
    debugger
    var exportdata: any = [];
    // resData.forEach((e: any) => {
    let data: any = {
      FirstName: e.first_name ? e.first_name : "",
      lastName: e.last_name ? e.last_name : "",
      Email: e.email ? e.email.email_id : "",
      Phone: e.phone ? e.phone.phone_no : "",
      Address: e.address ? e.address : "",
      City: e.city ? e.city : "",
      State: e.state ? e.state : "",
      Country: e.country ? e.country : "",
      ZipCode: e.zipCode ? e.zipCode : "",
      amountShow: e.amount ? e.amount.show_amount : 0,
      AmountPaid: e.amount ? e.amount.paid : 0,
      // EducationMajor: e.educational_background?e.educational_background.major:"",
      // EducationInstName: e.educational_background?e.educational_background.institute_name:"",
      // EducationYear: e.educational_background?e.educational_background.year:'0000',
      // EducationCertified: e.educational_background?e.educational_background.certified:0,
      // ProfessionalTitle: e.professional_background?e.professional_background.title:"",
      // ProfessionalInstName: e.professional_background?e.professional_background.institute_name:"",
      // ProfessionalExp: e.professional_background?e.professional_background.experience:"",




      // SessionDetails: e.request_session,
      // PackageDetails: e.package_details
    }
    let Subject = []
    for (let i = 0; i < e.subject_data.length; i++) {
      Subject.push(e.subject_data[i].subject_name)
    }
    data['Subject'] = Subject.toString();
    let Grade = []
    for (let i = 0; i < e.grade_data.length; i++) {
      Subject.push(e.grade_data[i].grade_level)
    }
    data['Grade'] = Subject.toString();
    exportdata.push(data);

    // e.package_details.forEach(details => {
    //   let packageDetails = {
    //     PackageName: details.packageId ? details.packageId.package_name : "",
    //     PackagePrice: details.session_price,
    //     Count: details.session_count,
    //   }
    //   exportdata.push(packageDetails);

    // });
    e.request_session.forEach(element => {
      let session = {
        StudentName: element.student_id ? element.student_id.first_name : "",
        StudentEmail: element.student_id ? element.student_id.email.email_id : "",
        StudentPhone: element.student_id ? element.student_id.phone.phone_no : "",
        Subject: element.subject_id ? element.subject_id.subject_name : "",
        Grade: element.grade_id ? element.grade_id.grade_level : "",
        Slot: element.slot_time,
        Time: element.select_time,
        Date: element.select_date,
        Plateform: element.plateform,
      }
      if (element.schedule == "1") {
        session['Status'] = "Pending"
      } else if (element.schedule == '2') {
        session['Status'] = "Cancel"

      } else if (element.schedule == '3') {
        session['Status'] = "Reschedule"

      } else if (element.schedule == '4') {
        session['Status'] = "Scheduled"

      } else if (element.schedule == '5') {
        session['Status'] = "Completed"

      }
      exportdata.push(session);

    });

    // });
    this.download.DownloadInExcel("Tutor Data", exportdata);
  }

  changeLeagueOwner() {
    this.subjectData = []
    for (let i = 0; i < this.selectSubjects.length; i++) {
      let data = {
        subject_id: this.selectSubjects[i]._id
      }
      this.subjectData.push(data)
    }
    console.log(this.subjectData)
  }

  changeLeagueOwner1() {
    this.gradeData = []
    for (let i = 0; i < this.selectGrade.length; i++) {
      let data = {
        grade_id: this.selectGrade[i]._id
      }
      this.gradeData.push(data)
    }
    console.log(this.gradeData)
  }

  onSubmit(value: any) {
    // debugger
    if (!this.tutor_id || !this.amountPaid || !this.amountShow || !this.rating) {
      return this.toastr.error("All mandatory field are required")
    } else {
      this.loader.start()
      let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
      if (this.amountPaid == "0" && this.amountShow == "0" && value == "verified") {
        this.loader.stop();
        return this.toastr.error("amount can't be 0")

      }
      // if (this.amountPaid <= this.amountShow) {
      //   this.loader.stop();
      //   return this.toastr.error("show amount can't be less than paid amount")

      // }
      let data = {
        user_id: localStorage.user_id,
        tutor_id: this.tutor_id,
        tutor_status: value,
        amount: { paid: this.amountPaid.toString(), show_amount: this.amountShow.toString() },
        tutor_rating: this.rating
      }
      this.apiService
        .postMethod('/admin/tutor_status/', data)
        .subscribe((response: any) => {
          if (response.code == 200) {
            this.loader.stop()
            this.toastr.success(response.message);
            this.router.navigate(['/tutor']);
          } else {
            this.loader.stop();
            this.toastr.error(response.err);
          }
        });
    }
  }
}
