import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  // local url
  // apiUrl = "http://localhost:4000";

  //live url

  apiUrl = "https://kusa-api.moshimoshi.cloud";
  constructor(private http: HttpClient) { }



  postMethod(apiname, data) {
    return this.http.post(this.apiUrl + apiname, data);
  }
  getMethod(apiname: any) {
    return this.http.get(this.apiUrl + apiname);
  }
}
