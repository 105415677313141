import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-free-resource',
  templateUrl: './free-resource.component.html',
  styleUrls: ['./free-resource.component.scss']
})
export class FreeResourceComponent implements OnInit {
  htmlContent: any;
  imgData: any;
  showImg: boolean = false
  subjects: any = [];
  grades: any = [];
  title: '';
  blogList: any = [];
  selectSubjects: any = [];
  selectGrade: any = [];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  subjectData: any = []
  selectedGrade: any=[]
  constructor(private router: Router, private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService, private storageService: StorageServiceService,
    private route: ActivatedRoute,
    private htmlConvert: AngularEditorModule) { }

  ngOnInit(): void {
    this.getSubject()
    this.getGrades()
    this.getBlogs()

  }
  getBlogs() {
    this.loader.start()
    let data = {
      page: 0,
      limit: 10
    }
    this.apiService
      .postMethod('/user/free_resources_list', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.blogList = response.data

        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  removeFree(id: any) {
    let user = JSON.parse(this.storageService.getDataFromStorage('KUSA')!)
    this.loader.start()
    let data = {
      blogId: id._id,
      userId: user.user_id,
    }
    this.apiService
      .postMethod('/admin/add_free_resources', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.toastr.success(response.message);
          this.getBlogs()
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  onSubmit() {
    debugger
    if (!this.title || !this.htmlContent || !this.imgData || !this.subjectData || !this.selectedGrade) {
      return this.toastr.error(" All * field maindatory");
    }
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA')!)
    let data = {
      userId: localStorage.user_id,
      title: this.title,
      blogDesc: this.htmlContent,
      base64: this.imgData,
      subjectId: this.subjectData,
      gradeId: this.selectedGrade,
    }
    this.apiService
      .postMethod('/admin/add_free_resources', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          // console.log(response)
          this.toastr.success(response.message);
          this.getBlogs()
          this.showImg = false
          this.title = '';
          this.htmlContent = '';
          this.imgData = '';
          this.selectSubjects = '';
          this.selectGrade = '';
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  getSubject() {
    this.loader.start()
    let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA')!)
    let data = {
      key: 0
    }
    this.apiService
      .postMethod('/user/get_grade_subjects', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          // console.log(response)
          this.subjects = response.data
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  getGrades() {
    this.loader.start()
    let data = {
      key: 1
    }
    this.apiService
      .postMethod('/user/get_grade_subjects', data)
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          // console.log(response)
          this.grades = response.data
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  // selectSubject = (event) => {
  //   this.selectedSubjects = event.target.value
  // }
  // selectGrade = (event) => {
  //   this.selectedGrade = event.target.value
  // }
  imgUpload(event: any) {
    // debugger
    if (event.target.files && event.target.files[0]) {
      this.imgData = (event.target.files[0])
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgData = event.target.result
        this.showImg = true
        // console.log(this.imgData)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  changeLeagueOwner() {
    this.subjectData = []
    for (let i = 0; i < this.selectSubjects.length; i++) {
      let data = {
        subject_id: this.selectSubjects[i]._id
      }
      this.subjectData.push(data)
    }
    console.log(this.subjectData)
  }
  changeLeagueOwner1() {
    this.selectedGrade = []
    for (let i = 0; i < this.selectGrade.length; i++) {
      let data = {
        grade_id: this.selectGrade[i]._id
      }
      this.selectedGrade.push(data)
    }
    console.log(this.selectedGrade)
  }
}
