import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';
declare var $;

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'status','website', 'action'];
  dataSource: any;
  ELEMENT_DATA: any = []
  fromDate: any;
  codeName: any = '';
  value: any;
  discountType: any;
  description: any;
  showWebsite: boolean = false;
  maxCount: any;
  active: boolean = false;
  toDate: any;
  showToDate: boolean = false;
  promo_id: any = '';
  role: any
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private router: Router, private toastr: ToastrService,
    private apiService: ApiServiceService,
    private loader: NgxUiLoaderService, private storageService: StorageServiceService
  ) { }
  ngOnInit(): void {
    this.promoList()

  }
  openModal() {
    $('#exampleModalCenter').show()
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;

  }
  closeModal() {
    this.promo_id = ''
    $('#exampleModalCenter').hide()
  }
  datePicker() {
    if (this.fromDate) {
      this.showToDate = true
      var todate = moment(new Date(this.fromDate));
    }
  }
  addPromo() {
    debugger
    if (!this.codeName || !this.value || !this.discountType || !this.description || !this.fromDate || !this.toDate  || !this.maxCount) {
      return this.toastr.error("All * fields are maindatory");
    } else {
      this.loader.start()
      let localStorage = JSON.parse(this.storageService.getDataFromStorage('KUSA'))
      let data = {
        user_id: localStorage.user_id,
        code_name: this.codeName,
        description: this.description,
        discount_type: this.discountType,
        discount_value: this.value,
        start_date: this.fromDate,
        end_date: this.toDate,
        active: this.active,
        website: this.showWebsite,
        max_count: this.maxCount
      }
      if (this.promo_id) {
        data["promo_id"] = this.promo_id
      }
      this.apiService
        .postMethod('/admin/add_promo/', data)
        .subscribe((response: any) => {
          if (response.code == 200) {
            console.log(response.data);
            this.loader.stop()
            this.closeModal()
            this.promoList()
            this.toastr.success(response.message);
          } else {
            this.loader.stop();
            this.toastr.error(response.err);
          }
        });
    }
  }
  promoList() {
    this.loader.start()
    this.apiService
      .getMethod('/admin/promo_list/')
      .subscribe((response: any) => {
        if (response.code == 200) {
          this.loader.stop()
          this.ELEMENT_DATA = response.data
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          //  console.log(this.dataSource);
        } else {
          this.loader.stop();
          this.toastr.error(response.err);
        }
      });
  }
  viewdetails(data) {
    this.codeName = data.code_name;
    this.description = data.description;
    this.discountType = data.discount_type;
    this.value = data.discount_value;
    this.fromDate = moment(data.start_date).format("YYYY-MM-DD");
    this.toDate = moment(data.end_date).format("YYYY-MM-DD");
    this.active = data.active;
    this.showWebsite = data.website;
    this.maxCount = data.max_count;
    this.promo_id = data._id
    $('#exampleModalCenter').show()

  }
  promo() {
    this.codeName = '';
    this.description = '';
    this.discountType = '';
    this.value = '';
    this.fromDate = '';
    this.toDate = '';
    this.active = false;
    this.showWebsite =false;
    this.maxCount = '';
    this.promo_id = ''
    this.openModal()
  }
}
